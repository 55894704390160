import React, { useEffect, useRef, useState } from "react";

import { GraphicCallout, Loading, Tabs } from "@commonComponents";
import type {
  DashboardExperience,
  EmbeddingContext,
  EmbeddingEvents,
} from "amazon-quicksight-embedding-sdk";
import { createEmbeddingContext } from "amazon-quicksight-embedding-sdk";
import axios from "axios";
import { styled } from "styled-components";

import { gridSpacing, checkPermissions, colorTheme } from "@utils";

import type { QuicksightProps } from "./helpers";
import type { ListInsightsDashboard } from "~/src/types";
import { trackPageView } from "~/src/utils/tsUtils";

const IFrame = styled.iframe`
  width: 100%;
  height: calc(100% - 115px); // 115px is the height of the header
  border: none;
`;

type QuicksightDashboardProps = {
  dashboard?: ListInsightsDashboard | QuicksightProps;
  consoleView?: boolean;
};

type ErrorsProp = {
  requestError?: string;
  notFound?: string;
};

type SheetOptionsType = {
  label: string;
  value: string;
};

const QuicksightDashboard = ({
  dashboard,
  consoleView,
}: QuicksightDashboardProps) => {
  const dashboardRef = useRef(null);
  const [embeddingContext, setEmbeddingContext] =
    useState<EmbeddingContext | null>(null);
  const [dashboardUrl, setDashboardUrl] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [errors, setErrors] = useState<ErrorsProp>({});
  const [currentSheet, setCurrentSheet] = useState<string>("");
  const [sheetOptions, setSheetOptions] = useState<SheetOptionsType[]>([]);
  const [embeddedDashboard, setEmbeddedDashboard] =
    useState<DashboardExperience | null>(null);

  const createContext = async () => {
    const context = await createEmbeddingContext();
    setEmbeddingContext(context);
  };

  const resizeIframe = () => {
    // Hack to make height 100% to screen
    const embeddingContainer = document.getElementById("embeddingContainer");
    if (embeddingContainer?.children[0]) {
      setTimeout(() => {
        const iframe = embeddingContainer.children[0] as HTMLIFrameElement;
        iframe.height = "15000";
      }, 100);
    }
  };

  const onDashboardLoad = async () => {
    setLoading(false);

    if (embeddedDashboard) {
      const sheets = await embeddedDashboard.getSheets();

      setCurrentSheet(sheets[0]?.SheetId);
      setSheetOptions(
        sheets.map((option) => {
          return {
            label: option.Name,
            value: option.SheetId,
          };
        }),
      );
    }
  };

  const onError = () => {
    const _error = "Something went wrong loading the dashboard";

    setErrors({
      ...errors,
      requestError: _error,
    });
  };

  const renderDashboard = async () => {
    if (embeddingContext) {
      const embeddingContainer = document.getElementById("embeddingContainer");
      if (embeddingContainer) {
        embeddingContainer.innerHTML = "";
      }

      const newEmbeddedDashboard = await embeddingContext.embedDashboard(
        {
          url: dashboardUrl,
          container: "#embeddingContainer",
          height: "1000px",
          width: "100%",
          onChange: (changeEvent: EmbeddingEvents) => {
            // @ts-ignore - TS doesn't recognize eventName as a valid property even though it comes straight from the readme
            if (changeEvent.eventLevel === "ERROR") {
              onError();
              return;
            }

            if (changeEvent.eventName === "FRAME_LOADED") {
              onDashboardLoad();
            } else if (changeEvent.eventName === "SIZE_CHANGED") {
              const height = parseInt(
                changeEvent.data?.height?.toString() || "0",
              );
              if (height < 250 && window.innerHeight > 250) {
                resizeIframe();
              }
            }
          },
        },
        {
          toolbarOptions: {
            export: true,
            reset: true,
            undoRedo: true,
          },
          locale: "en-US",
        },
      );
      setEmbeddedDashboard(newEmbeddedDashboard);
    }
  };

  useEffect(() => {
    setLoading(true);
    setSheetOptions([]);

    if (consoleView) {
      if (!checkPermissions("insights.view_console")) {
        setErrors({
          ...errors,
          requestError: "You do not have permission to use the console",
        });
      } else {
        axios
          .get(`quicksight/embed/console`)
          .then((res) => {
            setDashboardUrl(res.data.result);
          })
          .catch((err) => {
            console.error(err);
            let errorMessage =
              "Something went wrong loading the insights console";
            if (err?.response?.status === 403) {
              errorMessage =
                "You do not have permission to access the insights console";
            }

            setErrors({
              ...errors,
              requestError: errorMessage,
            });
          });
      }
    } else {
      axios
        .get(
          `quicksight/embed/${
            dashboard &&
            "embedded_details" in dashboard &&
            "dashboard_uuid" in dashboard.embedded_details
              ? dashboard.embedded_details.dashboard_uuid
              : dashboard?.uuid
          }`,
        )
        .then((res) => {
          setDashboardUrl(res.data.result);
        })
        .catch((err) => {
          console.error(err);
          let errorMessage = "Something went wrong loading dashboard";
          if (err?.response?.status === 403) {
            errorMessage = "You do not have permission to access the dashboard";
          }
          setErrors({
            ...errors,
            requestError: errorMessage,
          });
        });
    }
  }, [dashboard]);

  useEffect(() => {
    if (!consoleView && dashboardRef.current && dashboardUrl) {
      renderDashboard();
    }
  }, [dashboardRef.current, dashboardUrl, embeddingContext]);

  useEffect(() => {
    createContext();
    if (consoleView) {
      trackPageView({
        pageName: "Insights Console",
        objectType: "Console",
        l1App: "Insights Studio",
      });
    }
  }, []);

  const renderHeader = () => {
    if (consoleView) {
      return (
        <h3 style={{ marginTop: gridSpacing[7], marginBottom: gridSpacing[6] }}>
          Insights Console
        </h3>
      );
    }
    return (
      <div>
        {sheetOptions.length > 1 && (
          <Tabs
            currentTab={currentSheet}
            onSelect={(e) => {
              setCurrentSheet(e);
              if (embeddedDashboard) {
                embeddedDashboard.setSelectedSheetId(e);
              }
              resizeIframe();
            }}
          >
            {sheetOptions.map((option) => (
              <Tabs.Content
                key={option.value}
                title={option.label}
                tabKey={option.value}
              />
            ))}
          </Tabs>
        )}
      </div>
    );
  };

  const renderBody = () => {
    if (errors.requestError || errors.notFound) {
      return (
        <GraphicCallout
          error="issue"
          header={errors.requestError || "Dashboard not found"}
        />
      );
    }

    if (consoleView) {
      return <IFrame src={dashboardUrl} />;
    }

    const height = `calc(100vh - ${sheetOptions.length > 0 ? 230 : 185}px)`;

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          background: colorTheme("white"),
          minHeight: height,
          maxHeight: height,
        }}
      >
        {loading && <Loading center style={{ marginTop: gridSpacing[11] }} />}
        <div
          ref={dashboardRef}
          style={{
            display: loading ? "none" : "flex",
            flexDirection: "column",
            flexGrow: 1,
            width: "100%",
            zIndex: 1,
            maxHeight: height,
          }}
          id="embeddingContainer"
        />
      </div>
    );
  };

  return (
    <>
      {renderHeader()}
      {renderBody()}
    </>
  );
};

export default QuicksightDashboard;
